
import React from 'react'

function policy() {
    if ("window" in global) {
        window.location.href = "/policy.pdf"
    }
    return <p>redirect</p>
}

export default policy